.buttons {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 0px 30px;
    .more-btn-div {
        position: relative;
        display: flex;
        justify-content: flex-end;
    }
}
.ranklist-form-div {
    padding: 30px 80px;
    display: flex;
    flex-direction: column;
    .ranklist-header {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .ranklist-image {
            height: 200px;
            width: 200px;
        }
        .input {
            align-self: flex-end;
            flex: 1;
            .name {
                width: 100%;
                &:not(.edit) {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
    }

    .ranklist-div {
        padding: 30px 0px;
        &.uneditable {
            .list-item {
                .img-div {
                    display: flex;
                }
                pointer-events: none;
            }
            
        }
    }

    .back-btn {
        padding-bottom: 20px;
        color: var(--darker-grey);
        cursor: pointer;
        &:hover {
            color: var(--black);
        }
    }

    .name {
        font-size: 4em;
        font-weight: 800;
        &.edit {
            border-bottom: 2px solid var(--dark-grey);
        }
        &.name-error {
            border-bottom: 2px solid red;
        }
    }

    .btn {
        margin-bottom: 40px;
    }

    // phones and small tablets
    @media screen and (max-width: 768px) {
        align-items: center;
        padding: 30px;
        .ranklist-header {
            flex-direction: column;
            align-items: center;
            justify-items: center;
            .input {
                font-size: .7em;
            }
        }
        .search-div {
            align-self: normal;
            .search-bar-div {
                flex-direction: column;
                gap: 20px;
            }
        }
        .ranklist-div {
            align-self: normal
        }
    }
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    div {
        display: flex;
        flex-direction: column;
    }

    p {
        font-size: .7rem;
        margin-top: 15px;
    }

    p span {
        cursor: pointer;
        text-decoration: underline;
    }

    p span:hover {
        color: var(--darker-grey)
    }
}
