.ranklist-page {
    display: grid;
    grid-template-columns: 200px auto 200px;
    grid-template-rows: auto 1fr auto;
    padding: 40px 80px;
    gap: 20px;

    .ranklist-name {
        display: flex;
        h1 {
            font-size: 4em;
            font-weight: 800;
            align-self: flex-end;
            cursor: pointer;
        }
    }

    .back-btn-div {
        grid-row: 1/2;
        grid-column: 1/4;
        margin-bottom: 30px;
    }

    .more-btn-div {
        position: relative;
        justify-self: end;
    }

    .ranklist {
        margin-top: 15px;
        grid-column: 1/4;
    }

    .img-grid {
        width: 200px;
        height: 200px;
    }
}