#navbar {
    width: 100%;
    padding: 20px 100px;
    background: var(--matcha);
    font-weight: 800;
    display: flex;
    justify-content: space-between;

    span, a {
        cursor: pointer;
    }
    a {
        text-decoration: none;
        color: black;
    }
    a:hover {
        color: var(--matcha-light)
    }

    // phones and small tablets
    @media screen and (max-width: 768px) {
        padding: 20px 50px;
    }
}