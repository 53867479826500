.ranklist-card {
    &:hover {
        background: var(--light-grey);
    }
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    text-decoration: none;
    color: black;
    h4 {
        padding-top: 10px;
    }
    .empty-img, .img-grid {
        width: 100%;
        height: 170px;
    }
    .img-grid {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        img {
            height: 85px;
        }
    }
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.empty-img {
    background-color: var(--grey);
    border-radius: 8px;
}

.img-div {
    img {
        border-radius: 4px;
    }
}

.img-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100px 100px;

    img {
        &:nth-of-type(1) {
            border-radius: 4px 0px 0px 0px;
            align-self: flex-end;
        }
        &:nth-of-type(2) {
            border-radius: 0px 4px 0px 0px;
            align-self: flex-end;
        }
        &:nth-of-type(3) {
            border-radius: 0px 0px 0px 4px;
        }
        &:nth-of-type(4) {
            border-radius: 0px 0px 4px 0px;
        }   
    }
}
