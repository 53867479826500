@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Plus Jakarta Sans', Helvetica, Arial, sans-serif;
}

:root {
  --white: #fafafa;
  --dark-white: #f6f6f4;
  --light-grey: #edede9;
  --grey: #e3e3de;
  --dark-grey: #d9d9d3;
  --darker-grey: #8e8e89;  
  --black: #393d3f;
  --matcha: rgb(133, 150, 133);
  --matcha-2: rgb(184, 194, 184);
  --matcha-light: rgb(202, 211, 202);
}

body {
  background: var(--white);
}

input {
  background: none;
  border: none;
  padding: 5px;
  outline: none;
}
/* #root {
  background: var(--white);
} */