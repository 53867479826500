#home-page {
    padding: 40px 30px;
    .header {
        display: flex;
        justify-content: space-between;
        font-size: 1.2em;
    }

    .ranklist-card-div {
        padding-top: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fit, 200px);
        grid-template-rows: repeat(auto-fit, 275px);
        gap: 50px;
        overflow: hidden;
        h4 {
            text-overflow: ellipsis;
            white-space: pre-wrap;
            overflow: hidden;
        }
        // phones and small tablets
        @media screen and (max-width: 768px) {
            justify-content: center;
        }
    }
}
