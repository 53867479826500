.dropdown-div {
    background: var(--matcha-light);
    padding: 3px;
    border-radius: 4px;
    position: absolute;
    top: 30px;
    right: 0;
}

.dropdown-item {
    white-space: nowrap;
    padding: 10px 50px 10px 10px;
    border-radius: 2px;
    cursor: pointer;
    font-size: .9rem;

    &:hover {
        background: var(--matcha-2);
    }

    &.red {
        color: red;
    }
}