.btn {
    width: fit-content;
    padding: 15px 30px;
    font-size: .8rem;
    border: 2px solid var(--matcha);
    border-radius: 5px;
    background: none;
    cursor: pointer;
    margin-top: 10px;
    color: black;
}

.edit-dialog-form {
    .btn {
        width: 100px;
        padding: 10px;
        align-self: flex-end;
    }
}

.btn:hover {
    background: var(--matcha);
}

.add-btn-div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
}

.add-btn {
    cursor: pointer;
    &:hover {
        color: var(--dark-grey);
    }
}

.back-btn,
.more-btn {
    cursor: pointer;
    font-variation-settings:
        'FILL' 0,
        'wght' 700,
        'GRAD' 0,
        'opsz' 48;

    &:hover {
        color: var(--dark-grey)
    } 
}

.active {
    color: var(--dark-grey);

    &:hover {
        color: var(--black)
    }
}