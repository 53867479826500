.list-item {
    cursor: pointer;
    :hover {
        background-color: var(--light-grey);
    }
   
    .list-info {
        border-radius: 3px;
        padding: 10px 15px 10px 0px;
        display: grid;
        align-items: center;
        grid-template-rows: 20px 20px;
        grid-template-columns: auto 1fr 1fr;
        // phones and small tablets
        @media screen and (max-width: 768px) {
            grid-template-columns: auto 2fr 1fr;
        }
        .img-div {
            grid-row: 1 / 3;
            display: grid;
            align-items: center;
            margin-right: 5px;
            img {
                width: 35px;
                height: 35px;
                object-fit: cover;
                margin-left: 12px;
                border-radius: 3px;
            }
        }
        
        // &.removable {
        //     // phones and small tablets
        //     @media screen and (max-width: 768px) {
        //         grid-template-columns: auto 2fr 1fr;
        //     }
        // }
        &.removable,
        .ranklist & {
            .img-div {   
                grid-template-columns:  .5fr 1fr;
                p {
                    justify-self: end;
                }
            }
        }
        .title {
            font-size: .9rem;
            align-self: flex-end;
            &.type-artist {
                grid-row: 1/3;
                align-self: center;
            }
        }
        .artist,
        .album ,
        .index {
            font-size: .7rem;
            color: var(--black);
        }
        .artist,
        .album,
        .title {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding: 0px 5px;
        }
        .album {
            grid-column: 3 / 4;
            grid-row: 1 / 3;
            align-self: center;
            padding-left: 7px;
        }
        .material-symbols-outlined {
            grid-column: 4 / 5;
            grid-row: 1 / 3;
            color: var(--darker-grey);
            &:hover {
                color: var(--dark-grey);
            }
        }
    }
}